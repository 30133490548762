import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

import { UserPreferenceOptionsResponse } from '../models/user-preferences.model';


@Injectable({
	providedIn: 'root'
})
export class UserPreferencesService {
	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri?.apiBaseUrl;

	readonly #httpClient = inject(HttpClient);

	getAllPreferences(): Observable<Record<string, string>> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/all`;

		return this.#httpClient.get<Record<string, string>>(apiUrl);
	}

	getPreference(key: string): Observable<string> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}`;

		return this.#httpClient.get<{ value: string }>(apiUrl)
			.pipe(
				map(project => project.value)
			);
	}

	savePreference(key: string, value: string): Observable<void> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}`;
		const body = { value };

		return this.#httpClient.put<void>(apiUrl, body);
	}

	deletePreference(key: string): Observable<void> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}`;

		return this.#httpClient.delete<void>(apiUrl);
	}

	getPreferenceOptions(key: string): Observable<UserPreferenceOptionsResponse> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}/options`;

		return this.#httpClient.get<UserPreferenceOptionsResponse>(apiUrl);
	}

	saveDefaultKeyPreference(key: string, value: string): Observable<void> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}/default`;
		const body = { value };

		return this.#httpClient.put<void>(apiUrl, body);
	}

	savePreferenceByUserId(key: string, value: string, userId: string): Observable<void> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}/users/${encodeURIComponent(userId)}`;
		const body = { value };

		return this.#httpClient.put<void>(apiUrl, body);
	}

	getPreferenceByUserId(key: string, userId: string): Observable<string> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}/users/${encodeURIComponent(userId)}`;

		return this.#httpClient.get<{ value: string }>(apiUrl)
			.pipe(
				map(project => project.value)
			);
	}

	deletePreferenceByUserId(key: string, userId: string): Observable<string> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}/users/${encodeURIComponent(userId)}`;

		return this.#httpClient.delete<string>(apiUrl);
	}

	getAllPreferenceByUserId(userId: string): Observable<Record<string, string>> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/all/users/${encodeURIComponent(userId)}`;

		return this.#httpClient.get<Record<string, string>>(apiUrl);
	}
}
