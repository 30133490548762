import { createSelector } from '@ngrx/store';

import { UserPreferencesOptionsState } from '../reducers/user-preferences-options.reducers';

export const selectUserPreferencesOptionsStoreFeature = (state) => state.userPreferencesOptionsStore as UserPreferencesOptionsState;

export const selectUserPreferencesOptions = (key: string) => createSelector(
	selectUserPreferencesOptionsStoreFeature,
	(state) => state[key]
);
